import { useEffect, useState } from 'react';
import { customStyles } from './datatable-styles';
import DataTable from 'react-data-table-component';
import get from 'lodash/get';

const IbisDataTable = ({ data, columns, title, rowClickFunction, conditionalRowStyles, defaultSortFieldId, defaultSortAsc, emptyRecordsMessage }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filterCategory, setFilterCategory] = useState(columns.find((column) => column.sortable).filter_field);
  const [filterText, setFilterText] = useState('');
  const [dataLoading, setDataLoading] = useState(true);

  const NoRecords = () => {
    return emptyRecordsMessage || `No ${title?.toLowerCase() || 'records'} found.`;
  };

  useEffect(() => {
    if (!data) return;
    setFilteredData(
      data.filter((item) => {
        return get(item, filterCategory)?.toString().toLowerCase().includes(filterText?.toLowerCase());
      })
    );

    setTimeout(() => {
      setDataLoading(false);
    }, 700);
  }, [data, filterCategory, filterText]);

  return (
    <>
      <div className='d-flex justify-content-end'>
        <div className='d-flex flex-column' style={{ margin: '0rem 1.25rem 1.25rem 3px' }}>
          <label htmlFor='filter-select'>Filter by...</label>
          <select
            style={{
              width: '15vw',
              height: '30px',
              padding: '0.25rem',
              borderRadius: '2px',
            }}
            name='filter-select'
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            {columns.map((column) => {
              if (column.filter_field && !column.omit) {
                return (
                  <option key={column.name} value={column.filter_field}>
                    {column.name}
                  </option>
                );
              } else return null;
            })}
          </select>
        </div>
        <div className='d-flex flex-column' style={{ margin: '0rem 0rem 1.25rem 3px' }}>
          <label htmlFor='filter-text'>Filter term...</label>
          <input type='text' name='filter-text' value={filterText} onChange={(e) => setFilterText(e.target.value)} />
        </div>
      </div>
      <div className='card shadow mb-5'>
        <div className='card-header bg-primary-ibis py-3'>
          <h6 className='m-0 font-weight-bold text-white'>{title}</h6>
        </div>
        <div className='card-body'>
          <DataTable
            data={filteredData}
            columns={columns}
            customStyles={customStyles}
            noDataComponent={<NoRecords />}
            pagination={data?.length > 10}
            progressPending={dataLoading}
            highlightOnHover={Boolean(rowClickFunction)}
            pointerOnHover={Boolean(rowClickFunction)}
            onRowClicked={rowClickFunction}
            striped={data?.length > 1}
            conditionalRowStyles={conditionalRowStyles}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc ?? true}
          />
        </div>
      </div>
    </>
  );
};

export default IbisDataTable;
