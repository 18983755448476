import { usePopover } from '../../hooks';
import IbisDataTable from '../DataTable/IbisDataTable';

import RecheckActionsPopover from './RecheckActionsPopover';

const RecheckSessionsList = ({ sessions }) => {
  const { showPopover, setShowPopover } = usePopover();

  const getActionsButton = (session) => {
    return (
      <>
        <button
          type='button'
          id={`action-btn-${session.id}`}
          className='btn btn-md'
          onClick={() => handlePopover(session.id)}
          onBlur={() => setShowPopover(false)}
        >
          <i className='fas fa-ellipsis-v' />
        </button>
        <RecheckActionsPopover session={session} showPopover={showPopover === session.id} setShowPopover={setShowPopover} />
      </>
    );
  };

  const handlePopover = (id) => {
    if (showPopover === id) {
      setShowPopover(false);
    } else setShowPopover(id);
  };

  const columns = [
    {
      name: 'Patient',
      selector: (session) => session.user,
      sortable: true,
      wrap: true,
      filter_field: 'user',
    },

    {
      name: 'Date',
      selector: (session) => session.created,
      sortable: true,
      filter_field: 'created',
      sortFunction: (a, b) => a.created_timestamp - b.created_timestamp,
    },
    // {
    //   name: 'Session Type',
    //   selector: (session) => (session.session_type === 'full' ? 'Full Session' : 'Partial Session'),
    //   minWidth: '150px',
    //   sortable: true,
    //   filter_field: 'session_type',
    // },
    {
      name: 'Status',
      selector: (session) => session.status_label,
      sortable: true,
      filter_field: 'status_label',
      style: { textTransform: 'capitalize' },
    },
    {
      name: 'Latest activity',
      selector: (session) => session.latest_activity,
      sortable: true,
      filter_field: 'latest_activity',
      style: { textTransform: 'capitalize' },
    },
    {
      name: 'Latest change',
      selector: (session) => session.latest_activity_date_time,
      sortable: true,
      filter_field: 'latest_activity_date_time',
      style: { textTransform: 'capitalize' },
    },
    {
      name: 'Actions',
      cell: (session) => getActionsButton(session),
      button: true,
      width: '90px',
    },
  ];

  return (
    <IbisDataTable
      title='Recheck Sessions'
      data={sessions.length ? sessions : null}
      columns={columns}
      defaultSortFieldId={2}
      defaultSortAsc={false}
    />
  );
};

export default RecheckSessionsList;

// const resultStyles = [
//   {
//     when: (row) => row.result === 'success',
//     style: {
//       backgroundColor: 'rgb(0, 199, 78)',
//       color: '#fff',
//       fontWeight: 'bold',
//       textTransform: 'capitalize',
//     },
//   },
//   {
//     when: (row) => row.result === 'fail',
//     style: {
//       backgroundColor: 'rgb(255, 98, 78)',
//       color: '#fff',
//       fontWeight: 'bold',
//       textTransform: 'capitalize',
//     },
//   },
// ];
