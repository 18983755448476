import { createContext, useContext, useState } from 'react';
import { getAllSessions } from '../api/recheckFunctions';

const RecheckContext = createContext();

export const useRecheck = () => {
  return useContext(RecheckContext);
};

export const RecheckProvider = ({ children }) => {
  const [sessions, setSessions] = useState([]);

  const revalidate = () => getAllSessions().then((res) => setSessions(res));

  return <RecheckContext.Provider value={{ sessions, setSessions, revalidate }}>{children}</RecheckContext.Provider>;
};
