import RecheckSessionsList from './RecheckSessionsList';
import { useEffect, useState } from 'react';
import { getAllSessions } from '../../api/recheckFunctions';
import { SESSION_STATUSES } from '../../helper/recheck';
import { useRecheck } from '../../context/recheckContext';

const RecheckSessions = () => {
  const [showCompletedSessions, setShowCompletedSessions] = useState(false);
  const [showClosedSessions, setShowClosedSessions] = useState(false);
  const { sessions: allSessions, setSessions: setAllSessions } = useRecheck();
  const sessions = allSessions.filter((session) => {
    if (session.status === SESSION_STATUSES.COMPLETE && !showCompletedSessions) return false;
    if (session.status === SESSION_STATUSES.CLOSED && !showClosedSessions) return false;
    return true;
  });

  useEffect(() => {
    getAllSessions().then((res) => setAllSessions(res));
  }, [setAllSessions]);

  return (
    <div className='container-fluid'>
      <ToggleSwitch
        id='showCompletedSessionsSwitch'
        checked={showCompletedSessions}
        onChange={() => setShowCompletedSessions(!showCompletedSessions)}
        label='Include Complete Sessions'
      />
      <ToggleSwitch
        id='showInvalidSessionsSwitch'
        checked={showClosedSessions}
        onChange={() => setShowClosedSessions(!showClosedSessions)}
        label='Include Closed Sessions'
      />
      <RecheckSessionsList sessions={sessions} />
    </div>
  );
};

export default RecheckSessions;

const ToggleSwitch = ({ id, checked, onChange, label }) => {
  return (
    <div className='d-sm-flex align-items-center justify-content-between mb-3'>
      <div className='custom-control custom-switch'>
        <input type='checkbox' className='custom-control-input' id={id} checked={checked} onChange={onChange} />
        <label className='custom-control-label' style={{ color: '#000' }} htmlFor={id}>
          <h5 className='m-0'>{label}</h5>
        </label>
      </div>
    </div>
  );
};
