import { patchData } from './apiFunctions';
import { fetchObjects } from './crudFunctions';
import { postData } from './apiFunctions';

const apiUrl = '/api/recheck/sessions';

export const getAllSessions = async () => {
  const res = await fetchObjects(apiUrl);

  try {
    const { results } = res;
    return results;
  } catch (error) {
    console.error('Error fetching sessions:', error);
    return [];
  }
};

export const getSession = async (id) => {
  const res = await fetchObjects(`${apiUrl}/${id}/`);

  try {
    return res;
  } catch (error) {
    console.error('Error fetching session:', error);
    return {};
  }
};

export const updateRecheckStatus = async (session, status) => {
  try {
    await patchData(`${apiUrl}/${session}/`, { status });
    return `Session marked as ${status}`;
  } catch {
    throw new Error('Error updating session status');
  }
};

export const sendRecheckEmail = async (session, type) => {
  const titleCaseType = type.charAt(0).toUpperCase() + type.slice(1);
  try {
    const response_data = await postData(`${apiUrl}/${session}/send-email/`, { email: type });
    if (response_data.error) return response_data.error;
    return `${titleCaseType} email sent`;
  } catch {
    throw new Error('Error sending email');
  }
};
